<template>
    <master-panel>
        <template v-slot:body>
            <v-row>
                <v-col cols="12" v-if="loading">
                    <loading-spinner line height="0"/>
                </v-col>
                <v-col cols="12" v-else>
                    <clients withEmit @click="showClientResponses" :clients="clients"/>
                </v-col>
                <v-col cols="12" class="text-center" v-if="clients.length === 0">
                    <small>{{ loading ? 'Cargando...' : 'Sin resultados' }}</small>
                </v-col>
            </v-row>
            <response-dialog ref="response"/>
        </template>
        <template v-slot:buttons>
            <v-row>
                <v-col cols="6" align="right" class="pa-1 pl-3">
                    <v-btn block color="secondary" class="normal-btn" small depressed @click="goBack">
                        Volver
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pa-1 pr-3">
                    <v-btn block color="primary" class="normal-btn" small depressed @click="goHome">
                        Dashboard
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";
import Clients from "@/components/lists/Clients";
import httpService from "@/services/httpService";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import ResponseDialog from "@/components/client/ResponseDialog";

export default {
    name: "Responses",
    components: {ResponseDialog, LoadingSpinner, Clients, MasterPanel},
    data() {
        return {
            loading: false,
            clients: []
        }
    },
    computed: {
        doctorId(vm = this) {
            return vm.currentUser?.doctor_id || null
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClients()
    },
    methods: {
        async loadClients() {
            const vm = this
            vm.loading = true
            let response = await httpService.get(`doctor/${vm.doctorId}/responses`)
            // console.log('loadClients', response)
            vm.clients = response.data.clients
            vm.loading = false
        },
        showClientResponses(client) {
            const vm = this
            vm.$refs.response.setClient(client)
            vm.$refs.response.show()
        }
    },
}
</script>

<style scoped>

</style>